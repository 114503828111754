@tailwind base;
@tailwind components;
@tailwind utilities;



.slick-next{
  right: 10%;
  z-index: 50;
}
.slick-prev{
  left:0%;
  z-index: 50;
}
.slick-prev, .slick-next {
  font-size: 15px !important;
  z-index: 50;
}

 .slick-prev:before, .slick-next:before  {
  content: '' !important;
}